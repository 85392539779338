<script setup>
import { useCommon } from "@/store";

const overflowHidden = ref(false);

const notifications = computed(() => {
  return useCommon().getNotifications;
});

const cookie = computed(() => {
  return useCommon().cookie;
});

onMounted(() => {
  if (useCookie("accept_cookies").value === undefined) {
    useCommon().setCookie(true);
  } else {
    useCommon().setCookie(false);
  }
});
</script>

<template>
  <div>
    <navigation @toggle="overflowHidden = $event" />
    <div class="font-sf-pro">
      <transition
        name="fade"
        tag="div"
      >
        <cookie-confirm v-if="!!cookie" />
      </transition>
      <notification :items="notifications" />
      <div :class="{ 'h-0 overflow-hidden': overflowHidden }">
        <slot />
      </div>
    </div>
    <footer-item />
  </div>
</template>

<style lang="scss">
.fade-enter-active {
  @apply transition-opacity duration-300 ease-in;
}

.fade-leave-active {
  @apply transition-opacity duration-300 ease-out;
}

.fade-enter,
.fade-leave-to {
  @apply opacity-0;
}
</style>
