<script setup>
import { useCommon } from "@/store/index";

const isMobile = ref(false);
onMounted(() => {
  checkMobile();
  window.addEventListener("resize", checkMobile);
});

const checkMobile = () => {
  isMobile.value = window.innerWidth < 768;
};

const consent = () => {
  useCommon().cookie_consent();
};
</script>

<template>
  <div class="cookie">
    <div
      class="cookie-container container fixed mx-auto left-0 right-0 bottom-14 md:bottom-16 px-0 z-100"
    >
      <div class="cookie-content bg-white rounded-xl px-8 py-6 mx-4">
        <h4
          class="body-1 font-bold w-full px-4 md:pl-44"
          v-text="$t('COOKIETITLE')"
        />
        <div class="flex flex-col items-center justify-center md:flex-row">
          <div>
            <svg-cookie
              :width="isMobile ? 96 : 124"
              :height="isMobile ? 96 : 124"
              class="md:ml-8"
            />
          </div>
          <div class="mt-1 px-4 md:pl-5">
            <p
              class="body-1 md:pr-8"
              v-text="$t('COOKIEDETAIL')"
            />
            <sc-button
              class="cookie__confirm mt-9 w-full md:w-auto py-4 px-10 inline-flex"
              primary
              :label="$t('GOTIT')"
              @click="consent"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/css/functions";
.cookie-content {
  box-shadow: 0 rem(11px) rem(31px) 0 #00000029;
}
</style>
